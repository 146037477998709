body {
  color: #000070; }

#white {
  color: #fff; }

#sym-link {
  color: #fff;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 0.8em; }

.text-font {
  font-family: 'Roboto', Arial, sans-serif; }

#main {
  background-color: #000070; }

#container {
  background: url("../assets/5.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/5.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/5.jpg', sizingMethod='scale')"; }

.navbar {
  width: 100%;
  background: none !important; }
  @media (max-width: 34em) {
    .navbar {
      background: black !important; } }
  .navbar .navbar-toggler {
    cursor: pointer;
    outline: 0; }
  .navbar .nav-link {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff; }
  .navbar .nav-item {
    padding: 0 1rem; }
    @media (max-width: 34em) {
      .navbar .nav-item {
        padding: 0; } }
    @media (max-width: 34em) {
      .navbar .nav-item .nav-link {
        font-weight: normal;
        color: #fff !important; } }

p {
  font-family: 'Roboto', Arial, sans-serif; }

li {
  font-family: 'Roboto', Arial, sans-serif; }

h1 {
  font-weight: bold;
  font-size: 3.5em;
  font-family: 'Imperfecta', Arial, sans-serif; }

h3 {
  font-weight: bold;
  font-size: 1.5em;
  font-family: 'Imperfecta', Arial, sans-serif; }

section.features .cards .card {
  text-align: center;
  background: #f2f2f2;
  padding: 2em;
  margin: 2em;
  border-radius: 3px;
  border: 0; }

section.features .cards .card:hover {
  box-shadow: 20px 20px 0px 0px #000; }

section.features .cards .card:first-child img {
  margin-bottom: 20px; }

#brand {
  color: #fff;
  font-family: 'Imperfecta', Arial, sans-serif; }

#icon {
  color: #000; }

section.features .cards .card p {
  margin-top: 25px;
  font-size: 1.1rem;
  color: #6f6f70; }

section.features .button-line {
  text-align: end;
  margin: 35px 35px 20px 35px;
  border-radius: 5px; }

.box-h3 {
  padding-top: 1em; }

@font-face {
  font-family: 'Imperfecta';
  src: url("../assets/fonts/Imperfecta-Regular.ttf"); }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Light.ttf"); }
